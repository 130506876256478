.footerGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
    padding: 1rem;
}
.footerGridPacchetti {
    grid-template-columns: 1fr 2.5fr 1fr;
}

.bgFooter {
    /*provvisorio*/
    background: rgb(39, 40, 122);
    background: linear-gradient(
            90deg,
            rgba(39, 40, 122, 1) 0%,
            rgba(167, 24, 86, 1) 100%
    );
}

.labelSmall {
    font-size: 11px;
}

.formFooter {
        input {
            height: 30px;
        }
        .PrivateSwitchBase-input {
            height: 15px;
        }
}


@media (max-width: 1000px) {
    .footerGrid {
        grid-template-columns: 1fr;
    }
}

.partners {
    font-size: 1.9rem;
    margin-top: 1rem
}

.partners text {
    font-size: 1.9rem !important;
}

