.conditional-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 1439px) {
  .conditional-flex {
    flex-direction: column;
    row-gap: 1rem;
  }
}
.golf-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap:10px;
}
/* .golf-grid input { */
/*   border: 3px solid #a71856 !important; */
/* } */
@media (max-width: 900px) {
  .golf-grid {
    grid-template-columns: 1fr;
  }
}
.labelx{
		margin:-7.5px;
font-weight: 700;
position: relative;
top:10px;
left:23px;
font-size:10px;
}
